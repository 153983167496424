import { Add, DeleteForever, LocalPhone, Public } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography
} from "@mui/material"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"
import { useI18nCountries } from "src/shared/i18n"

const MerchantDataForm = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "onboardingPage"
  })
  const { control, watch, setValue, getValues } = useFormContext()
  const isSoleProprietorshipSwitchOn = watch("isSoleProprietorship")
  const {
    fields: ceoDeputies,
    append,
    remove
  } = useFieldArray({
    name: "ceoDeputies"
  })
  const { possibleCountries } = useI18nCountries()
  const merchantFormData = {
    isSoleProprietorship: { inputName: "isSoleProprietorship" },
    companyName: {
      inputName: "companyName"
    },
    phone: {
      inputName: "phone"
    },
    legalForm: {
      inputName: "legalForm"
    },
    companyNameAddition: {
      inputName: "companyNameAddition"
    },
    homePage: {
      inputName: "homePage"
    },
    address: {
      street: {
        inputName: "address.street"
      },
      houseNumber: {
        inputName: "address.houseNumber"
      },
      addressAddition: {
        inputName: "address.addressAddition"
      },
      zip: {
        inputName: "address.zip"
      },
      city: {
        inputName: "address.city"
      },
      state: {
        inputName: "address.state"
      },
      country: {
        inputName: "address.country"
      }
    },
    companySize: {
      inputName: "companySize",
      possibleCompanySizes: [
        {
          text: t("defaultSelect", { keyPrefix: "common" }),
          value: ""
        },
        {
          text: t("merchantFormData.companySizeSmallText"),
          value: "SMALL"
        },
        {
          text: t("merchantFormData.companySizeMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("merchantFormData.companySizeBigText"),
          value: "BIG"
        }
      ]
    },
    companyRevenue: {
      inputName: "companyRevenue",
      possibleCompanyRevenue: [
        {
          text: t("defaultSelect", { keyPrefix: "common" }),
          value: ""
        },
        {
          text: t("merchantFormData.companyRevenueSmallText"),
          value: "SMALL"
        },
        {
          text: t("merchantFormData.companyRevenueMiddleText"),
          value: "MIDDLE"
        },
        {
          text: t("merchantFormData.companyRevenueBigText"),
          value: "BIG"
        }
      ]
    },
    ceo: {
      title: {
        inputName: "ceo.title"
      },
      firstName: {
        inputName: "ceo.firstName"
      },
      lastName: {
        inputName: "ceo.lastName"
      }
    },
    possibleSalutations: [
      {
        text: t("defaultSelect", { keyPrefix: "common" }),
        value: ""
      },
      {
        value: "MR",
        text: t("maleSalutation")
      },
      {
        value: "MRS",
        text: t("femaleSalutation")
      }
    ]
  }

  return (
    <Grid data-testid="onboardingMerchantDataForm">
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography
            variant="h2"
            gutterBottom
            data-testid="merchantInfoHeader"
          >
            {t("merchantFormData.companySubtitle")}
          </Typography>
        </Grid>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={merchantFormData.isSoleProprietorship.inputName}
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            onChange(e.target.checked)
                            if (getValues("isExemptFromTaxFiling")) {
                              setValue("isExemptFromTaxFiling", false)
                            }
                          }}
                          checked={value}
                        />
                      }
                      data-testid="isSoleProprietorshipCheckbox"
                      label={t(
                        "merchantFormData.isSoleProprietorshipCheckboxLabel"
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            {!isSoleProprietorshipSwitchOn && (
              <>
                <Grid item data-testid="companyNameInput" xs={12} md={8}>
                  <FormInputGroup
                    placeholder={t("merchantFormData.companyNamePlaceholder")}
                    labelName={t("merchantFormData.companyName")}
                    inputName={merchantFormData.companyName.inputName}
                    isLabelRequired={true}
                    rules={{
                      required: t(
                        "merchantFormData.companyNameRequiredErrorMessage"
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} data-testid="legalFormInput">
                  <FormInputGroup
                    placeholder={t("merchantFormData.legalFormPlaceholder")}
                    labelName={t("merchantFormData.legalForm")}
                    inputName={merchantFormData.legalForm.inputName}
                    isLabelRequired={true}
                    rules={{
                      required: t(
                        "merchantFormData.legalFormRequiredErrorMessage"
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  data-testid="companyNameAdditionInput"
                >
                  <FormInputGroup
                    placeholder={t(
                      "merchantFormData.companyNameAdditionPlaceholder"
                    )}
                    labelName={t("merchantFormData.companyNameAddition")}
                    inputName={merchantFormData.companyNameAddition.inputName}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6} data-testid="phoneInput">
              <FormInputGroup
                labelName={t("merchantFormData.phone")}
                inputName={merchantFormData.phone.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("merchantFormData.phoneRequiredErrorMessage")
                }}
                startIcon={<LocalPhone />}
                placeholder={t("merchantFormData.phonePlaceholder")}
              />
            </Grid>

            <Grid item data-testid="homepageInput" xs={12} md={6}>
              <FormInputGroup
                placeholder={t("merchantFormData.homePagePlaceholder")}
                labelName={t("merchantFormData.homePage")}
                inputName={merchantFormData.homePage.inputName}
                isLabelRequired={true}
                startIcon={<Public />}
                rules={{
                  required: t("merchantFormData.homePageRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInputGroup
                placeholder={t("merchantFormData.streetPlaceholder")}
                labelName={t("merchantFormData.street")}
                inputName={merchantFormData.address.street.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("merchantFormData.streetRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputGroup
                placeholder={t("merchantFormData.houseNumberPlaceholder")}
                labelName={t("merchantFormData.houseNumber")}
                inputName={merchantFormData.address.houseNumber.inputName}
                isLabelRequired={true}
                rules={{
                  required: t(
                    "merchantFormData.houseNumberRequiredErrorMessage"
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} data-testid="addressAdditionInput">
              <FormInputGroup
                placeholder={t("merchantFormData.addressAdditionPlaceholder")}
                labelName={t("merchantFormData.addressAddition")}
                inputName={merchantFormData.address.addressAddition.inputName}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormInputGroup
                placeholder={t("merchantFormData.zipPlaceholder")}
                labelName={t("merchantFormData.zip")}
                inputName={merchantFormData.address.zip.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("merchantFormData.zipRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInputGroup
                placeholder={t("merchantFormData.cityPlaceholder")}
                labelName={t("merchantFormData.city")}
                inputName={merchantFormData.address.city.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("merchantFormData.cityRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("merchantFormData.statePlaceholder")}
                labelName={t("merchantFormData.state")}
                inputName={merchantFormData.address.state.inputName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                labelName={t("merchantFormData.country")}
                inputName={merchantFormData.address.country.inputName}
                selectItems={possibleCountries()}
                isLabelRequired={true}
                rules={{
                  required: t("merchantFormData.countryRequiredErrorMessage")
                }}
                countryFlags={true}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                labelName={t("merchantFormData.companySize")}
                inputName={merchantFormData.companySize.inputName}
                selectItems={merchantFormData.companySize.possibleCompanySizes}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormSelectGroup
                labelName={t("merchantFormData.companyRevenue")}
                inputName={merchantFormData.companyRevenue.inputName}
                selectItems={
                  merchantFormData.companyRevenue.possibleCompanyRevenue
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("merchantFormData.ceo")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <FormSelectGroup
                labelName={t("title")}
                isLabelRequired={true}
                inputName={merchantFormData.ceo.title.inputName}
                selectItems={merchantFormData.possibleSalutations}
                rules={{
                  required: t("titleRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormInputGroup
                placeholder={t("firstNamePlaceholder")}
                labelName={t("firstName")}
                inputName={merchantFormData.ceo.firstName.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("firstNameRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormInputGroup
                placeholder={t("lastNamePlaceholder")}
                labelName={t("lastName")}
                inputName={merchantFormData.ceo.lastName.inputName}
                isLabelRequired={true}
                rules={{
                  required: t("lastNameRequiredErrorMessage")
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("merchantFormData.ceoDeputy")}
          </Typography>
        </Grid>
        <Container>
          <Grid container className="McpDeputiesBox">
            {ceoDeputies.map((ceoDeputy, index) => (
              <Grid
                item
                container
                key={ceoDeputy.id}
                className="McpDeputiesContainer"
                data-testid={`deputy_ceo_group_${index}`}
                spacing={2}
              >
                <Grid item xs={12} md={2}>
                  <FormSelectGroup
                    labelName={t("title")}
                    inputName={`ceoDeputies.${index}.title`}
                    isLabelRequired={true}
                    selectItems={merchantFormData.possibleSalutations}
                    rules={{
                      required: t("titleRequiredErrorMessage")
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormInputGroup
                    placeholder={t("firstNamePlaceholder")}
                    labelName={t("firstName")}
                    inputName={`ceoDeputies.${index}.firstName`}
                    isLabelRequired={true}
                    rules={{
                      required: t("firstNameRequiredErrorMessage")
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormInputGroup
                    placeholder={t("lastNamePlaceholder")}
                    labelName={t("lastName")}
                    inputName={`ceoDeputies.${index}.lastName`}
                    isLabelRequired={true}
                    rules={{
                      required: t("lastNameRequiredErrorMessage")
                    }}
                  />
                </Grid>

                <Grid item xs={12} className="deleteRow">
                  <Button
                    data-testid={`removeDeputyBtn${index}`}
                    size="small"
                    variant="contained"
                    color="error"
                    startIcon={<DeleteForever />}
                    aria-label="remove"
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    <Box component="span" className="deleteText">
                      {t("remove", { keyPrefix: "common" })}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12} textAlign="center">
              <Button
                data-testid="addDeputyBtn"
                size="small"
                color="primary"
                aria-label="add"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  append(
                    {
                      title: "MR",
                      firstName: "",
                      lastName: "",
                      email: null,
                      phone: null
                    },
                    { shouldFocus: false }
                  )
                }}
              >
                {t("merchantFormData.addDeputy")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Grid>
  )
}

export default MerchantDataForm
