import { LocalPhone, MailOutline } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"

const ContactDataForm = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "onboardingPage"
  })

  const { control } = useFormContext()

  const contactFormData = {
    dataProtectionOfficer: {
      title: { inputName: "dataProtectionOfficer.title" },
      firstName: { inputName: "dataProtectionOfficer.firstName" },
      lastName: { inputName: "dataProtectionOfficer.lastName" },
      email: { inputName: "dataProtectionOfficer.email" },
      phone: { inputName: "dataProtectionOfficer.phone" }
    },
    support: {
      email: { inputName: "support.email" },
      phone: { inputName: "support.phone" }
    },
    finance: {
      email: { inputName: "finance.email" },
      phone: { inputName: "finance.phone" }
    },
    development: {
      email: { inputName: "development.email" },
      phone: { inputName: "development.phone" }
    },
    techSupport: {
      email: { inputName: "techSupport.email" },
      phone: { inputName: "techSupport.phone" }
    },
    possibleSalutations: [
      {
        text: t("defaultSelect", { keyPrefix: "common" }),
        value: ""
      },
      {
        value: "MR",
        text: t("maleSalutation")
      },
      {
        value: "MRS",
        text: t("femaleSalutation")
      }
    ]
  }

  return (
    <Box data-testid="onboardingContactDataForm">
      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("contactFormData.dataProtectionOfficer")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="isDataProtectionOfficerExternal"
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange} checked={value} />}
                      data-testid="dataProtectionCheckbox"
                      label={t(
                        "contactFormData.dataProtectionOfficerCheckboxLabel"
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormSelectGroup
                labelName={t("title")}
                inputName={
                  contactFormData.dataProtectionOfficer.title.inputName
                }
                isLabelRequired={true}
                selectItems={contactFormData.possibleSalutations}
                rules={{
                  required: t("titleRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item data-testid="dpoFirstNameInput" xs={12} md={5}>
              <FormInputGroup
                placeholder={t("firstNamePlaceholder")}
                labelName={t("firstName")}
                inputName={
                  contactFormData.dataProtectionOfficer.firstName.inputName
                }
                isLabelRequired={true}
                rules={{
                  required: t("firstNameRequiredErrorMessage")
                }}
              />
            </Grid>
            <Grid item data-testid="dpoLastNameInput" xs={12} md={5}>
              <FormInputGroup
                placeholder={t("lastNamePlaceholder")}
                labelName={t("lastName")}
                inputName={
                  contactFormData.dataProtectionOfficer.lastName.inputName
                }
                isLabelRequired={true}
                rules={{
                  required: t("lastNameRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("emailPlaceholder")}
                labelName={t("email")}
                startIcon={<MailOutline />}
                inputName={
                  contactFormData.dataProtectionOfficer.email.inputName
                }
                isLabelRequired={true}
                rules={{
                  required: t("emailRequiredErrorMessage")
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("phonePlaceholder")}
                labelName={t("phone")}
                startIcon={<LocalPhone />}
                inputName={
                  contactFormData.dataProtectionOfficer.phone.inputName
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("contactFormData.support")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                labelName={t("email")}
                startIcon={<MailOutline />}
                placeholder={t("emailPlaceholder")}
                inputName={contactFormData.support.email.inputName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("phonePlaceholder")}
                labelName={t("phone")}
                startIcon={<LocalPhone />}
                inputName={contactFormData.support.phone.inputName}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("contactFormData.finance")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("emailPlaceholder")}
                labelName={t("email")}
                startIcon={<MailOutline />}
                inputName={contactFormData.finance.email.inputName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("phonePlaceholder")}
                labelName={t("phone")}
                startIcon={<LocalPhone />}
                inputName={contactFormData.finance.phone.inputName}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("contactFormData.development")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("emailPlaceholder")}
                labelName={t("email")}
                startIcon={<MailOutline />}
                inputName={contactFormData.development.email.inputName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("phonePlaceholder")}
                labelName={t("phone")}
                startIcon={<LocalPhone />}
                inputName={contactFormData.development.phone.inputName}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="McpBox">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("contactFormData.techSupport")}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("emailPlaceholder")}
                labelName={t("email")}
                startIcon={<MailOutline />}
                inputName={contactFormData.techSupport.email.inputName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInputGroup
                placeholder={t("phonePlaceholder")}
                labelName={t("phone")}
                startIcon={<LocalPhone />}
                inputName={contactFormData.techSupport.phone.inputName}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default ContactDataForm
