import { LinkProps } from "@mui/material"
import { forwardRef } from "react"
import { NavLink, NavLinkProps, useLocation } from "react-router-dom"

const Navlink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps & LinkProps & { isNested: boolean }
>((props, ref) => {
  const { isNested, ...rest } = props
  const { pathname } = useLocation()

  const isNavlinkActive = () => {
    if (isNested) {
      return pathname === props.to || pathname.startsWith(`${props.to}/`)
    }

    return props.to === "/"
      ? pathname === props.to
      : pathname.startsWith(`${props.to}`)
  }

  return (
    <NavLink
      ref={ref}
      {...rest}
      className={() =>
        isNavlinkActive() ? rest.className + " Mui-selected" : rest.className
      }
      end
    />
  )
})

Navlink.displayName = "Navlink"
export default Navlink
