import { useAtom } from "jotai"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { LoadingContainer } from "src/shared/components"
import { routes } from "src/shared/routing"
import { onboardingDataAtom } from "src/shared/stores"
import {
  AccountDataForm,
  ContactDataForm,
  DocumentsListForm,
  MerchantDataForm,
  OnboardingStepper,
  SummaryForm
} from "src/widgets/onboarding"

const OnboardingPage = () => {
  const navigate = useNavigate()
  const [{ data: onboardingData }] = useAtom(onboardingDataAtom)

  const useFormMethods = useForm({
    values: onboardingData,
    mode: "all"
  })

  useEffect(() => {
    if (
      onboardingData &&
      onboardingData?.status !== "IN_PROGRESS" &&
      onboardingData?.status !== "WAITING_FOR_MERCHANT" &&
      onboardingData?.status !== "NOT_YET_STARTED"
    ) {
      navigate(routes.dashboard)
    }
  }, [navigate, onboardingData, onboardingData?.status])

  if (!onboardingData) {
    return <LoadingContainer />
  }

  return (
    <FormProvider {...useFormMethods}>
      <OnboardingStepper>
        <MerchantDataForm />
        <ContactDataForm />
        <AccountDataForm />
        <DocumentsListForm />
        <SummaryForm />
      </OnboardingStepper>
    </FormProvider>
  )
}

export default OnboardingPage
