import { Delete } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { useAtom } from "jotai"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { ApiError } from "src/shared/client"
import { Tooltip } from "src/shared/components"
import { saveFile, truncateText } from "src/shared/functions"
import {
  deleteOnboardingDocumentDataAtom,
  downloadOnboardingDocumentAtom,
  onboardingDocumentDataAtom
} from "src/shared/stores"

const UploadedDocument = ({
  document,
  isDeletable,
  isDownloadable
}: {
  document: OnboardingDocument
  isDeletable: boolean
  isDownloadable: boolean
}) => {
  const { t } = useTranslation("translation")
  const { enqueueSnackbar } = useSnackbar()

  const [downloadDocument] = useAtom(downloadOnboardingDocumentAtom)
  const [{ refetch: refetchUploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const [{ mutate: deleteOnboardingDocument, isPending: isDeletingDocument }] =
    useAtom(deleteOnboardingDocumentDataAtom)

  const handleDownloadDocumentClicked = async (
    onboardingDocument: OnboardingDocument
  ) => {
    try {
      const file = await downloadDocument(onboardingDocument.documentId)
      saveFile(file, onboardingDocument.filename)

      enqueueSnackbar({
        variant: "success",
        message: t("onboardingPage.documentsFormData.documentDownloadSuccess", {
          fileName: onboardingDocument.filename
        })
      })
    } catch (error) {
      if (error instanceof ApiError) {
        const response: ServiceError = await error.errorResponse.json()

        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(response.code, { keyPrefix: "errorCodes" }),
          details: response.message,
          autoHideDuration: null
        })
      }
    }

    window.document.querySelector<HTMLButtonElement>("#nextButton")?.focus()
  }

  const handleDeleteDocument = async (
    onboardingDocument: OnboardingDocument
  ) => {
    deleteOnboardingDocument(onboardingDocument.documentId, {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: t("onboardingPage.documentsFormData.documentDeleteSuccess", {
            fileName: onboardingDocument.filename
          })
        })

        refetchUploadedOnboardingDocuments()
      },
      onError: async (error) => {
        const response: ServiceError = await error.errorResponse.json()

        enqueueSnackbar({
          variant: "detailedSnackbar",
          message: t(response.code, { keyPrefix: "errorCodes" }),
          details: response.message,
          autoHideDuration: null
        })
      }
    })

    window.document.querySelector<HTMLButtonElement>("#nextButton")?.focus()
  }

  const fileName = document.filename.replace(/\.[^/.]+$/, "") ?? ""
  const documentType = document.filename.replace(/.*\./, "") ?? ""
  const truncatedText = truncateText(fileName, 25)

  return (
    <Grid
      data-testid="singleUploadedFile"
      container
      item
      xs={12}
      key={document.documentId}
      className="McpDocument"
    >
      <Grid xs={"auto"} display={"flex"} item alignItems={"center"}>
        <Box>
          <picture>
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDEyNC4xIDE2Mi4xIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMDAwOwogICAgICAgIHN0cm9rZS13aWR0aDogMHB4OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0wLDgwLjdjMC0yMC4yLDAtNDAuMywwLTYwLjVDMCw3LjMsNy4yLDAsMjAuMSwwLDM5LjksMCw1OS43LDAsNzkuNSwwYzIuNywwLDQuNy43LDYuNiwyLjYsMTEuNywxMS45LDIzLjUsMjMuNiwzNS4zLDM1LjMsMiwxLjksMi42LDMuOSwyLjYsNi42LDAsMzIuNywwLDY1LjMsMCw5OCwwLDEyLjItNy40LDE5LjUtMTkuNywxOS41LTI4LjIsMC01Ni4zLDAtODQuNSwwLTEyLjUsMC0xOS44LTcuMy0xOS44LTE5LjksMC0yMC41LDAtNDEsMC02MS41Wk00LDgxLjFjMCwyMC43LDAsNDEuMywwLDYyLDAsOS4xLDUuNiwxNC45LDE0LjUsMTUsMjksLjEsNTgsLjEsODcsMCw4LjcsMCwxNC4zLTUuOCwxNC41LTE0LjUuMS00LjcsMC05LjMsMC0xNCwwLTI3LjUtLjEtNTUsLjEtODIuNSwwLTQuMy0xLjItNS40LTUuMy01LjEtNSwuNC0xMCwuMi0xNSwwLTEwLjEtLjMtMTcuNi03LjgtMTcuOC0xNy44LS4xLTUuMi0uMy0xMC4zLDAtMTUuNS4yLTMuNy0uOC00LjgtNC42LTQuOC0xOSwuMi0zOCwwLTU3LC4xLTExLjIsMC0xNi41LDUuMy0xNi41LDE2LjUsMCwyMC4yLDAsNDAuMywwLDYwLjVaTTg0LDQuN2MwLDYuOC0uMiwxMy41LDAsMjAuMS4zLDcuNCw1LjgsMTQuMSwxMy4xLDE0LjgsNy4zLjcsMTQuOC4yLDIxLjkuMi0xMS42LTExLjYtMjMuMi0yMy4yLTM1LTM1LjFaIi8+CiAgPHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTEyLDk5LjJjMCwxMC4yLS40LDE5LjEsMCwyOCwxLjEsMTcuNC01LjUsMjMuNS0yMi44LDIyLjktMjAtLjctNDAtLjEtNTkuOS0uMi0xMS40LDAtMTcuNC01LjktMTcuNC0xNy4xLDAtMzQuNiwwLTY5LjMsMC0xMDMuOSwwLTQuMy43LTguMywzLjYtMTEuNiwxLTEuMSwxLjgtMi45LDUuMS0xLjgtNy44LDQuOS02LjcsMTIuMy02LjcsMTkuNCwwLDMyLjMsMCw2NC42LDAsOTYuOSwwLDExLjQsNC43LDE2LjIsMTUuOSwxNi4yLDIxLjUsMCw0MywwLDY0LjQsMCwxMC43LDAsMTUuNi00LjksMTUuNi0xNS41LDAtOS4yLDAtMTguMywwLTI3LjUsMC0xLjUtLjYtMy4zLDItNS44WiIvPgogIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTExMC4zLDk3LjZ2LTE1LjFjLjMsMCwuNiwwLDEsMHYxNS4xYy0uMywwLS42LDAtMSwwWiIvPgo8L3N2Zz4="
              alt="file"
              width={1}
              height={1}
            />
          </picture>
          <Grid className="McpDocumentType">
            {documentType.toUpperCase() ?? ""}
          </Grid>
        </Box>
      </Grid>
      <Grid xs={true} container item alignItems={"center"}>
        <Tooltip text={`${fileName}.${documentType}`} className="noBorder">
          <Typography>
            <Button
              className="McpDocumentDownload"
              variant="text"
              data-testid="downloadOnboardingDocumentBtn"
              onClick={() => handleDownloadDocumentClicked(document)}
              disabled={isDeletingDocument || !isDownloadable}
            >
              {truncatedText}.{documentType}
            </Button>
          </Typography>
        </Tooltip>
        {isDeletable && (
          <Button
            color="error"
            disabled={isDeletingDocument}
            data-testid="deleteOnboardingDocumentBtn"
            onClick={() => handleDeleteDocument(document)}
            startIcon={<Delete />}
            size="small"
          >
            {t("onboardingPage.documentsFormData.documentDeleteButton")}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default UploadedDocument
