import { KeyboardArrowRight } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Grid } from "@mui/material"
import type { BaseSyntheticEvent } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FormSelectGroup } from "src/shared/components"
import { languages } from "src/shared/variables"

const LanguageView = ({
  handleUpdateMerchant,
  isUpdatingMerchantData
}: {
  handleUpdateMerchant: (e: BaseSyntheticEvent) => Promise<void>
  isUpdatingMerchantData: boolean
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "accountPage.company.languageTab"
  })

  const languageInfo = {
    companyLanguage: {
      inputName: "companyLanguage",
      labelName: t("companyLanguage"),
      possibleCompanyLanguages: languages.map((lng) => ({
        text: lng.nativeName,
        value: lng.lngCode.toUpperCase(),
        flagCode: lng.flagCode
      }))
    }
  }

  return (
    <Box data-testid="merchantLanguageInfoContainer" className="McpForm">
      <Grid container item rowSpacing={2} columnSpacing={4}>
        <Grid item xs={12} md={6}>
          <FormSelectGroup
            labelName={languageInfo.companyLanguage.labelName}
            inputName={languageInfo.companyLanguage.inputName}
            selectItems={languageInfo.companyLanguage.possibleCompanyLanguages}
            countryFlags={true}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Box>
            <Trans
              i18nKey="accountPage.company.languageTab.companyLanguageDescription"
              components={{ 1: <br />, 2: <b /> }}
            ></Trans>
          </Box>
        </Grid>

        <Grid item xs={12} className="McpButtons">
          <Grid>
            <LoadingButton
              loading={isUpdatingMerchantData}
              fullWidth
              variant="contained"
              color="primary"
              data-testid="changeCompanyLanguageBtn"
              onClick={handleUpdateMerchant}
            >
              {t("saveBtn", { keyPrefix: "common" })} <KeyboardArrowRight />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LanguageView
