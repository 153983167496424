import { Check, Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import {
  postProjectStatusChangeAtom,
  projectStatusChangeListAtom
} from "src/shared/stores"

type ProjectStatusChangeConfirmationDialogProps = {
  comment: string | null
  handleStatusChange: (status: ProjectStatus) => void
  isDialogOpen: boolean
  setIsDialogOpen: (newState: boolean) => void
  newProjectStatus: "READY_FOR_APPROVAL"
}

const ProjectStatusChangeConfirmationDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  newProjectStatus,
  comment,
  handleStatusChange
}: ProjectStatusChangeConfirmationDialogProps) => {
  const { t } = useTranslation("translation")

  const [{ isFetching: isFetchingProjectStatusData }] = useAtom(
    projectStatusChangeListAtom
  )
  const [{ isPending: isPostingProjectStatusChange }] = useAtom(
    postProjectStatusChangeAtom
  )

  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle id="alert-dialog-title">
        {t("projectDetails.statusConfirmationDialog.dialogTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          {t("projectDetails.statusConfirmationDialog.dialogDescriptionText")}
        </DialogContentText>
        <DialogContentText>
          {t("projectDetails.statusConfirmationDialog.availableCommentText")}
        </DialogContentText>
        <DialogContentText
          whiteSpace={"pre-wrap"}
          data-testid="alert-dialog-comment-description"
        >
          {comment !== null && comment !== ""
            ? comment
            : t(
                "projectDetails.statusConfirmationDialog.noAvailableCommentText"
              )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="McpButtons">
          <Button
            color="error"
            data-testid="dialog-cancel-button"
            disabled={
              isFetchingProjectStatusData || isPostingProjectStatusChange
            }
            onClick={() => setIsDialogOpen(!isDialogOpen)}
            autoFocus
            startIcon={<Close />}
          >
            {t("common.cancelBtn")}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            data-testid="dialog-submit-button"
            loading={
              isFetchingProjectStatusData || isPostingProjectStatusChange
            }
            onClick={() => {
              handleStatusChange(newProjectStatus)
            }}
            startIcon={<Check />}
          >
            {t("common.resubmitBtn")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectStatusChangeConfirmationDialog
