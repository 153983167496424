import { Chip as MUIChip, ChipOwnProps } from "@mui/material"
import { forwardRef, RefObject } from "react"
import useChipConfig from "src/shared/components/api/useChipConfig"

type ChipLabelType = "onboardings" | "merchants" | "projects"

const ChipForTooltip = forwardRef(
  (
    {
      status,
      pathname,
      variant,
      setFilterStatus,
      resetFilterStatus
    }: {
      status?: UnionStatus
      pathname?: ChipLabelType
      variant?: ChipOwnProps["variant"]
      setFilterStatus?: (value: UnionStatus) => void
      resetFilterStatus?: () => void
    },
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { chipColor, CHIP_DEFAULT_TEXT, chipLabel } = useChipConfig()

    return !status || !pathname ? (
      <MUIChip
        ref={ref}
        data-testid={status}
        variant={variant || "filled"}
        color="primary"
        label={CHIP_DEFAULT_TEXT}
        size="small"
      />
    ) : (
      <MUIChip
        ref={ref}
        data-testid={status}
        variant={variant || "filled"}
        size="small"
        color={chipColor[status]}
        label={chipLabel[status][pathname]}
        onClick={() =>
          setFilterStatus && status !== "ALL"
            ? setFilterStatus(status)
            : resetFilterStatus && status === "ALL"
              ? resetFilterStatus()
              : null
        }
      />
    )
  }
)

ChipForTooltip.displayName = "ChipForTooltip"
export default ChipForTooltip
