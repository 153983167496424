import { ErrorMessage } from "@hookform/error-message"
import { LocalPhone, MailOutline } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material"
import { useAtom } from "jotai"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  DepartmentSummaryItem,
  SummaryItem,
  UploadedDocument
} from "src/features/onboarding"
import { LoadingContainer } from "src/shared/components"
import { filterFormData } from "src/shared/functions"
import { useI18nCountries } from "src/shared/i18n"
import {
  onboardingDataAtom,
  onboardingDocumentDataAtom,
  updateOnboardingDataAtom
} from "src/shared/stores"

const SummaryForm = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "onboardingPage"
  })

  const {
    control,
    getValues,
    formState: { errors },
    watch
  } = useFormContext()

  //condition for MR and CI render
  const isMandateReferenceAndCreditorIdentifierReady =
    watch("bankAccount.hasDirectDebitMandate") &&
    watch("bankAccount.mandateReference") &&
    watch("bankAccount.creditorIdentifier")

  // condition for direct debit checkbox call
  const isHasDirectDebitCallPossible =
    !watch("bankAccount.hasDirectDebitMandate") &&
    !watch("bankAccount.mandateReference") &&
    !watch("bankAccount.creditorIdentifier")

  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const [
    {
      data: onboardingData,
      refetch: refetchOnboardingData,
      isLoading: isLoadingOnboardingData,
      isError: isErrorFetchingOnboardingData
    }
  ] = useAtom(onboardingDataAtom)

  const [{ mutateAsync: updateOnboardingData }] = useAtom(
    updateOnboardingDataAtom
  )

  const handleConfirmDirectDebit = async (formData: OnboardingRecord) => {
    await updateOnboardingData({
      updatedOnboardingData: filterFormData(formData) as OnboardingRecord
    })
    await refetchOnboardingData()
  }

  const { countryNameFromAlpha2 } = useI18nCountries()

  const ceoDeputies: Person[] | undefined = getValues("ceoDeputies")

  const getCompanySize = (size: string) => {
    switch (size) {
      default:
        return "~"
      case "SMALL":
        return t("merchantFormData.companySizeSmallText")
      case "MIDDLE":
        return t("merchantFormData.companySizeMiddleText")
      case "BIG":
        return t("merchantFormData.companySizeBigText")
    }
  }

  const getCompanyRevenue = (revenue: string) => {
    switch (revenue) {
      default:
        return "~"
      case "SMALL":
        return t("merchantFormData.companyRevenueSmallText")
      case "MIDDLE":
        return t("merchantFormData.companyRevenueMiddleText")
      case "BIG":
        return t("merchantFormData.companyRevenueBigText")
    }
  }

  const getPerson = (personObject: Person) =>
    `${personObject.title === "MR" ? t("maleSalutation") : t("femaleSalutation")} ${personObject.firstName} ${personObject.lastName}`

  const getDepartmentPhone = (department: Department) =>
    department ? department.phone : undefined
  const getDepartmentEmail = (department: Department) =>
    department ? department.email : undefined

  if (isLoadingOnboardingData) {
    return <LoadingContainer />
  }

  if (isErrorFetchingOnboardingData) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100px"
      >
        {t("summaryFormData.onboardingDataFetchFailureAlert")}
      </Box>
    )
  }

  return (
    <Box data-testid="onboardingSummaryPage">
      {/* COMPANY DATA */}
      <Box className="McpBox" data-testid="sectionCompanyData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom data-testid="summaryInfoHeader">
            {t(
              getValues("isSoleProprietorship")
                ? "summaryFormData.soleProprietorSubtitle"
                : "summaryFormData.companySubtitle"
            )}
          </Typography>
        </Grid>
        <Container>
          <Grid container spacing={2}>
            <Grid container item rowSpacing={1} columnSpacing={2} xs={12}>
              {!getValues("isSoleProprietorship") && (
                <>
                  <Grid item xs={12} md={8} data-testid="summaryCompanyName">
                    <SummaryItem
                      labelName={t("merchantFormData.companyName")}
                      itemName={getValues("companyName")}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} data-testid="summaryLegalForm">
                    <SummaryItem
                      labelName={t("merchantFormData.legalForm")}
                      itemName={getValues("legalForm")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SummaryItem
                      labelName={t("merchantFormData.companyNameAddition")}
                      itemName={getValues("companyNameAddition")}
                      inputName="companyNameAddition"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.phone")}
                  itemName={getValues("phone")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.homePage")}
                  itemName={getValues("homePage")}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <SummaryItem
                  labelName={t("merchantFormData.street")}
                  itemName={getValues("address.street")}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <SummaryItem
                  labelName={t("merchantFormData.houseNumber")}
                  itemName={getValues("address.houseNumber")}
                />
              </Grid>

              <Grid item xs={12} data-testid="summaryAddressAddition">
                <SummaryItem
                  labelName={t("merchantFormData.addressAddition")}
                  itemName={getValues("address.addressAddition")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.zip")}
                  itemName={getValues("address.zip")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.city")}
                  itemName={getValues("address.city")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.state")}
                  itemName={getValues("address.state")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box component="span" />
                <SummaryItem
                  labelName={t("merchantFormData.country")}
                  itemName={countryNameFromAlpha2({
                    isoCountryCode: getValues("address.country")
                  })}
                  countryFlags={getValues("address.country")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.companySize")}
                  itemName={getCompanySize(getValues("companySize"))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SummaryItem
                  labelName={t("merchantFormData.companyRevenue")}
                  itemName={getCompanyRevenue(getValues("companyRevenue"))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* CEO & CO */}
      <Box className="McpBox" data-testid="sectionContactData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("summaryFormData.ceosSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container rowSpacing={2}>
            <Grid container item xs={12} alignItems={"center"}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("merchantFormData.ceo")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <SummaryItem
                  itemName={getPerson(getValues("ceo"))}
                  inputName="ceo"
                />
              </Grid>
            </Grid>

            {ceoDeputies && ceoDeputies.length > 0 && (
              <Grid container item xs={12} alignItems={"center"}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    {ceoDeputies.length > 1
                      ? t("merchantFormData.ceoDeputies")
                      : t("merchantFormData.ceoDeputy")}
                  </Typography>
                </Grid>
                <Grid container item xs={9} spacing={1}>
                  {ceoDeputies.map((deputy, index) => (
                    <Grid container item xs={12} key={`deputy-${index}`}>
                      <SummaryItem
                        itemName={getPerson(deputy)}
                        inputName="ceoDeputy"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      {/* CONTACT DATA */}
      <Box className="McpBox" data-testid="sectionContactData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom>
            {t("summaryFormData.contactSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container columnSpacing={2} rowSpacing={4}>
            {/* DATA PROTECTOR */}
            <Grid container item xs={12} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contactFormData.dataProtectionOfficer")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} lg={4}>
                    <SummaryItem
                      labelName={t(
                        "contactFormData.dataProtectionOfficerCheckboxLabel"
                      )}
                      itemName={
                        getValues("isDataProtectionOfficerExternal")
                          ? t("yes")
                          : t("no")
                      }
                    />
                  </Grid>
                  <Grid container item xs={12} lg={8}>
                    <Grid item xs={12} lg={12}>
                      <SummaryItem
                        labelName={t("summaryFormData.contactSubtitle")}
                        itemName={getPerson(getValues("dataProtectionOfficer"))}
                        inputName="dataProtectionOfficer"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item columnSpacing={2} xs={12} rowSpacing={1}>
                    <DepartmentSummaryItem
                      labelPhone={t("phone")}
                      phone={getValues("dataProtectionOfficer").phone}
                      labelEmail={t("email")}
                      email={getValues("dataProtectionOfficer").email}
                      iconPhone={<LocalPhone />}
                      iconEmail={<MailOutline />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* SUPPORT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contactFormData.support")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("phone")}
                    phone={getDepartmentPhone(getValues("support"))}
                    labelEmail={t("email")}
                    email={getDepartmentEmail(getValues("support"))}
                    iconPhone={<LocalPhone />}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* FINANCE */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contactFormData.finance")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("phone")}
                    phone={getDepartmentPhone(getValues("finance"))}
                    labelEmail={t("email")}
                    email={getDepartmentEmail(getValues("finance"))}
                    iconPhone={<LocalPhone />}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* DEVELOPMENT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contactFormData.development")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("phone")}
                    phone={getDepartmentPhone(getValues("development"))}
                    labelEmail={t("email")}
                    email={getDepartmentEmail(getValues("development"))}
                    iconPhone={<LocalPhone />}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* DEVELOPMENT */}
            <Grid container item xs={12} alignItems={"center"} rowSpacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contactFormData.techSupport")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
                  <DepartmentSummaryItem
                    labelPhone={t("phone")}
                    phone={getDepartmentPhone(getValues("techSupport"))}
                    labelEmail={t("email")}
                    email={getDepartmentEmail(getValues("techSupport"))}
                    iconPhone={<LocalPhone />}
                    iconEmail={<MailOutline />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* ACCOUNT DATA */}
      <Box className="McpBox" data-testid="sectionAccountData">
        <Grid item className="McpBoxHeader">
          <Typography variant="h2" gutterBottom data-testid="summaryInfoHeader">
            {t("summaryFormData.accountSubtitle")}
          </Typography>
        </Grid>
        <Container>
          <Grid container item rowSpacing={1} columnSpacing={2} xs={12}>
            <Grid item xs={12}>
              <SummaryItem
                labelName={t("accountFormData.accountHolder")}
                itemName={getValues("bankAccount.accountHolder")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SummaryItem
                labelName="BIC / SWIFT-Code"
                itemName={getValues("bankAccount.bic")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SummaryItem
                labelName="IBAN"
                itemName={getValues("bankAccount.iban")}
              />
            </Grid>

            {getValues("bankAccount.type") === "SEPA" &&
              isMandateReferenceAndCreditorIdentifierReady && (
                <>
                  <Grid item xs={12} md={6}>
                    <SummaryItem
                      labelName={t("accountFormData.creditorIdentifier")}
                      itemName={onboardingData?.bankAccount?.creditorIdentifier}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SummaryItem
                      labelName={t("accountFormData.mandateReference")}
                      itemName={onboardingData?.bankAccount?.mandateReference}
                    />
                  </Grid>
                </>
              )}
          </Grid>

          {getValues("bankAccount.type") === "NON_SEPA" && (
            <>
              <Grid
                container
                item
                xs={12}
                rowSpacing={2}
                columnSpacing={2}
                alignItems={"flex-start"}
              >
                {/* BENEFICIARY */}
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  data-testid="beneficiaryInfo"
                  mt={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("accountFormData.beneficiary")}
                    </Typography>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={8}>
                      <SummaryItem
                        labelName={t("accountFormData.street")}
                        itemName={getValues("bankAccount.holderAddress.street")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SummaryItem
                        labelName={t("accountFormData.houseNumber")}
                        itemName={getValues(
                          "bankAccount.holderAddress.houseNumber"
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("accountFormData.zip")}
                        itemName={getValues("bankAccount.holderAddress.zip")}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("accountFormData.city")}
                        itemName={getValues("bankAccount.holderAddress.city")}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justifyContent={"flex-end"}
                  >
                    <Grid item xs={12}>
                      <SummaryItem
                        labelName={t("accountFormData.country")}
                        itemName={countryNameFromAlpha2({
                          isoCountryCode: getValues(
                            "bankAccount.holderAddress.country"
                          )
                        })}
                        countryFlags={getValues(
                          "bankAccount.holderAddress.country"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* BANK DETAILS */}
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  data-testid="bankDetailsInfo"
                  mt={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      {t("accountFormData.bankDetails")}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid container item xs={6}>
                      <SummaryItem
                        labelName={t("accountFormData.bankName")}
                        itemName={getValues("bankAccount.bankAddress.name")}
                      />
                    </Grid>
                    <Grid container item xs={6}>
                      <SummaryItem
                        labelName={t("accountFormData.accountNumber")}
                        itemName={getValues("bankAccount.accountNumber")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("accountFormData.street")}
                        itemName={getValues("bankAccount.bankAddress.street")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("accountFormData.houseNumber")}
                        itemName={getValues(
                          "bankAccount.bankAddress.houseNumber"
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                      <SummaryItem
                        labelName={t("accountFormData.zip")}
                        itemName={getValues("bankAccount.bankAddress.zip")}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <SummaryItem
                        labelName={t("accountFormData.city")}
                        itemName={getValues("bankAccount.bankAddress.city")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12}>
                      <SummaryItem
                        labelName={t("accountFormData.country")}
                        itemName={countryNameFromAlpha2({
                          isoCountryCode: getValues(
                            "bankAccount.bankAddress.country"
                          )
                        })}
                        countryFlags={getValues(
                          "bankAccount.bankAddress.country"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Box>

      {/* DOCUMENTS DATA */}
      {(!getValues("isExemptFromTaxFiling") ||
        (getValues("isExemptFromTaxFiling") &&
          uploadedOnboardingDocuments &&
          uploadedOnboardingDocuments.length > 0)) && (
        <Box className="McpBox" data-testid="sectionDocumentsData">
          <Grid item className="McpBoxHeader">
            <Typography
              variant="h2"
              gutterBottom
              data-testid="summaryInfoHeader"
            >
              {getValues("isExemptFromTaxFiling")
                ? t("summaryFormData.documentsSubtitleNoVat")
                : t("summaryFormData.documentsSubtitle")}
            </Typography>
          </Grid>
          <Container>
            <Grid item container xs={12} className="McpDocuments" spacing={2}>
              {uploadedOnboardingDocuments?.map((document, index) => (
                <Grid item md={12} lg={6} key={`document-${index}`}>
                  <UploadedDocument
                    document={document}
                    isDeletable={false}
                    isDownloadable={false}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      )}

      {/* DIRECT DEBIT MANDATE CHECKBOX */}
      {getValues("bankAccount.type") !== "NON_SEPA" && (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="bankAccount.hasDirectDebitMandate"
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            onChange(e.target.checked)
                            if (isHasDirectDebitCallPossible) {
                              handleConfirmDirectDebit(
                                getValues() as OnboardingRecord
                              )
                            }
                          }}
                          checked={value}
                        />
                      }
                      data-testid="hasDirectDebitMandateCheckbox"
                      label={t(
                        "accountFormData.hasDirectDebitMandateCheckboxLabel"
                      )}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* AGB CHECKBOX */}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: t("summaryFormData.agbCheckboxRequiredErrorMessage")
              }}
              name="termsAndConditionsAccepted"
              render={({ field: { onChange } }) => (
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        data-testid="agbCheckbox"
                        onChange={onChange}
                        checked={!!getValues("termsAndConditionsAccepted")}
                        required
                      />
                    }
                    label={t("summaryFormData.agbCheckbox")}
                  />
                </FormGroup>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="termsAndConditionsAccepted"
              render={({ message }) => (
                <Box
                  component="span"
                  className="McpErrorMessage"
                  data-testid="errorMessage"
                  role="alert"
                >
                  {message}
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SummaryForm
