const handleTime = (date?: string) =>
  !date
    ? "--"
    : `${new Date(date).toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      })}`

export default handleTime
