import { ErrorMessage } from "@hookform/error-message"
import { Check, Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid
} from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useAtom, useSetAtom } from "jotai"
import { useSnackbar } from "notistack"
import type { BaseSyntheticEvent } from "react"
import { Controller } from "react-hook-form"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { FormInputGroup, FormSelectGroup } from "src/shared/components"
import { postNewProjectAtom, projectIdAtom, userAtom } from "src/shared/stores"

type CreateProjectDialogProps = {
  isDialogOpen: boolean
  toggleIsDialogOpen: () => void
  handleDrawerOpen: () => void
}

const CreateProjectDialog = ({
  isDialogOpen,
  toggleIsDialogOpen,
  handleDrawerOpen
}: CreateProjectDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [{ data: user }] = useAtom(userAtom)
  const [{ isPending: isPostingNewProject, mutateAsync: postNewProject }] =
    useAtom(postNewProjectAtom)
  const setProjectId = useSetAtom(projectIdAtom)

  const defaultProjectData: ProjectPostRequest = {
    merchantId: user?.merchantId,
    status: "READY_FOR_APPROVAL",
    activityStatus: "ACTIVE",
    name: "",
    url: "",
    ageRating: undefined,
    category: undefined,
    isProjectCompliesPrivacyPolicy: false
  }

  const useFormMethods = useForm<ProjectPostRequest>({
    mode: "all",
    values: defaultProjectData
  })
  const { t } = useTranslation("translation")

  const editDialog = {
    projectName: {
      inputName: "name",
      labelName: t("projectDetails.createProjectDialog.name"),
      placeholder: t("projectDetails.createProjectDialog.namePlaceholder"),
      rules: {
        required: t(
          "projectDetails.createProjectDialog.nameRequiredErrorMessage"
        )
      }
    },
    projectWebsite: {
      inputName: "url",
      labelName: t("projectDetails.createProjectDialog.url"),
      placeholder: t("projectDetails.createProjectDialog.urlPlaceholder"),
      rules: {
        required: t(
          "projectDetails.createProjectDialog.urlRequiredErrorMessage"
        )
      }
    },
    projectAgeRating: {
      inputName: "ageRating",
      labelName: t("projectDetails.createProjectDialog.ageRating"),
      possibleAgeRating: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK0"),
          value: "FSK0"
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK6"),
          value: "FSK6"
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK12"),
          value: "FSK12"
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK16"),
          value: "FSK16"
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK18"),
          value: "FSK18"
        },
        {
          text: t("projectDetails.createProjectDialog.possibleAgeRating.FSK21"),
          value: "FSK21"
        }
      ]
    },
    projectCategory: {
      inputName: "category",
      labelName: t("projectDetails.createProjectDialog.category"),
      possibleCategories: [
        {
          text: t("common.defaultSelect"),
          value: ""
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.entertainment"
          ),
          value: "entertainment"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.infotainment"
          ),
          value: "infotainment"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.gaming"
          ),
          value: "gaming"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.community"
          ),
          value: "community"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.ecommerce"
          ),
          value: "ecommerce"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.webshop"
          ),
          value: "webshop"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.hosting"
          ),
          value: "hosting"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.onlineService"
          ),
          value: "onlineService"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.nonProfit"
          ),
          value: "nonProfit"
        },
        {
          text: t(
            "projectDetails.createProjectDialog.possibleCategories.other"
          ),
          value: "other"
        }
      ]
    },
    projectPrivacyPolicyAccepted: {
      label: t("projectDetails.createProjectDialog.privacyPolicyCheckboxLabel"),
      rules: {
        required: t(
          "projectDetails.createProjectDialog.privacyPolicyCheckboxRequiredErrorMessage"
        )
      }
    }
  }

  const handleNavigationToCreatedProjectDetails = (newProjectId: string) => {
    setProjectId(newProjectId)
    handleDrawerOpen()

    const newUrl = `/projects/${newProjectId}?tab=1`
    navigate(newUrl)
  }

  const handlePostProject = (e: BaseSyntheticEvent) => {
    try {
      useFormMethods.handleSubmit(async (newProjectData) => {
        await postNewProject(
          {
            ...newProjectData,
            status: "READY_FOR_APPROVAL"
          },
          {
            onSuccess: (postedProject) => {
              queryClient.invalidateQueries({
                queryKey: ["projectListData"],
                refetchType: "active"
              })

              enqueueSnackbar({
                variant: "success",
                message: t("postNewProjectSuccessMessage")
              })

              toggleIsDialogOpen()
              useFormMethods.reset(defaultProjectData)

              handleNavigationToCreatedProjectDetails(
                postedProject.projectId as string
              )
            },
            onError: async (error) => {
              const response: ServiceError = await error.errorResponse.json()

              enqueueSnackbar({
                variant: "detailedSnackbar",
                message: t(`errorCodes.${response.code}`),
                details: response.message,
                autoHideDuration: null
              })

              toggleIsDialogOpen()
            }
          }
        )
      })(e)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormProvider {...useFormMethods}>
      <Dialog
        data-testid="createProjectDialog"
        open={isDialogOpen}
        disableScrollLock
        transitionDuration={{ enter: 225, exit: 0 }}
      >
        <DialogTitle id="alert-dialog-title">{t("title")}</DialogTitle>
        <DialogContent id="alert-dialog-content">
          <Grid container spacing={2}>
            <Grid item data-testid="projectNameInput" xs={6}>
              <FormInputGroup
                placeholder={editDialog.projectName.placeholder}
                labelName={editDialog.projectName.labelName}
                inputName={editDialog.projectName.inputName}
                isLabelRequired={true}
                rules={editDialog.projectName.rules}
              />
            </Grid>

            <Grid item data-testid="projectWebsiteInput" xs={6}>
              <FormInputGroup
                placeholder={editDialog.projectWebsite.placeholder}
                labelName={editDialog.projectWebsite.labelName}
                inputName={editDialog.projectWebsite.inputName}
                isLabelRequired={true}
                rules={editDialog.projectWebsite.rules}
              />
            </Grid>

            <Grid item xs={12} md={6} data-testid="projectAgeRatingInput">
              <FormSelectGroup
                labelName={editDialog.projectAgeRating.labelName}
                inputName={editDialog.projectAgeRating.inputName}
                selectItems={editDialog.projectAgeRating.possibleAgeRating}
              />
            </Grid>

            <Grid item xs={12} md={6} data-testid="projectCategoryInput">
              <FormSelectGroup
                labelName={editDialog.projectCategory.labelName}
                inputName={editDialog.projectCategory.inputName}
                selectItems={editDialog.projectCategory.possibleCategories}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={useFormMethods.control}
                name="isProjectCompliesPrivacyPolicy"
                rules={editDialog.projectPrivacyPolicyAccepted.rules}
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid="projectPrivacyPolicyCheckbox"
                          required
                          onChange={onChange}
                          checked={value}
                        />
                      }
                      label={editDialog.projectPrivacyPolicyAccepted.label}
                    />
                  </FormGroup>
                )}
              />
              <ErrorMessage
                errors={useFormMethods.formState.errors}
                name="isProjectCompliesPrivacyPolicy"
                render={({ message }) => (
                  <Box
                    component="span"
                    data-testid="errorMessage"
                    role="alert"
                    className="McpErrorMessage"
                  >
                    {message}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className="McpButtons">
            <Button
              disabled={isPostingNewProject}
              color="error"
              data-testid="dialog-cancel-button"
              onClick={() => {
                toggleIsDialogOpen()
                useFormMethods.reset(defaultProjectData)
              }}
              autoFocus
              startIcon={<Close />}
            >
              {t("common.cancelBtn")}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              data-testid="dialog-submit-button"
              loading={isPostingNewProject}
              onClick={(e) => {
                handlePostProject(e)
              }}
              startIcon={<Check />}
            >
              {t("common.saveBtn")}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export default CreateProjectDialog
