import { CssBaseline, ThemeProvider } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Provider as JotaiProvider } from "jotai"
import { useHydrateAtoms } from "jotai/utils"
import { queryClientAtom } from "jotai-tanstack-query"
import { SnackbarProvider } from "notistack"
import { ReactNode } from "react"
import { BrowserRouter } from "react-router-dom"
import { DetailedSnackbar } from "src/shared/components"
import { theme } from "src/shared/theming"

import useSnackbarAction from "./useSnackbarAction"

interface ProvidersProps {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  }
})

const HydrateAtoms = ({ children }: { children: ReactNode }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return children
}

const Providers = ({ children }: ProvidersProps) => {
  const { action } = useSnackbarAction()

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            <HydrateAtoms>
              <SnackbarProvider
                Components={{
                  detailedSnackbar: DetailedSnackbar
                }}
                autoHideDuration={3000}
                preventDuplicate={true}
                action={action}
                maxSnack={15}
              >
                <CssBaseline />
                {children}
              </SnackbarProvider>
            </HydrateAtoms>
          </JotaiProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Providers
