import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient, put as putClient } from "src/shared/client"

type UpdateProjectDataInput = {
  updatedProjectData: ProjectRecord
  signal?: AbortSignal
}

export const projectDetailsTabAtom = atom<string>("1")

export const projectIdAtom = atom<string | undefined>(undefined)

export const projectDataAtom = atomWithQuery<ProjectRecord, ApiError>((get) => {
  const projectId = get(projectIdAtom)

  return {
    queryKey: ["projectData", projectId],
    queryFn: async () => {
      const response = await (
        await getClient({
          path: `/project-service/projects/${projectId}`
        })
      ).json()

      return response
    },
    enabled: !!projectId
  }
})

export const updateProjectDataAtom = atomWithMutation<
  ProjectRecord,
  UpdateProjectDataInput,
  ApiError
>((get) => {
  const projectId = get(projectIdAtom)

  return {
    mutationFn: async ({
      updatedProjectData,
      signal
    }): Promise<ProjectRecord> => {
      const response = await (
        await putClient({
          path: `/project-service/projects/${projectId}`,
          body: updatedProjectData,
          signal
        })
      ).json()

      return response
    }
  }
})
