import { Check, Close } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material"
import { useAtom } from "jotai"
import { useTranslation } from "react-i18next"
import {
  projectDataAtom,
  projectListDataAtom,
  updateProjectDataAtom
} from "src/shared/stores"

type ProjectActivityConfirmationDialogProps = {
  isDialogOpen: boolean
  setIsDialogOpen: (newState: boolean) => void
  newActivityStatus: ProjectActivityStatus
  handleActivityStatusChange: (newActivityStatus: ProjectActivityStatus) => void
  isChecked: boolean
  setIsChecked: (newsState: boolean) => void
}

const ProjectActivityConfirmationDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  newActivityStatus,
  handleActivityStatusChange,
  isChecked,
  setIsChecked
}: ProjectActivityConfirmationDialogProps) => {
  const { t } = useTranslation("translation")

  const [{ isFetching: isFetchingProjectData }] = useAtom(projectDataAtom)
  const [{ isPending: isUpdatingProjectData }] = useAtom(updateProjectDataAtom)
  const [{ isFetching: isFetchingProjectListData }] =
    useAtom(projectListDataAtom)

  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle id="alert-dialog-title">
        {t(
          `projectDetails.activityConfirmationDialog.${newActivityStatus}.dialogTitle`
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          {t(
            `projectDetails.activityConfirmationDialog.${newActivityStatus}.dialogDescriptionText`
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="McpButtons">
          <Button
            color="error"
            data-testid="dialog-cancel-button"
            disabled={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectListData
            }
            onClick={() => {
              setIsDialogOpen(!isDialogOpen)
              setIsChecked(!isChecked)
            }}
            autoFocus
            startIcon={<Close />}
          >
            {t("common.cancelBtn")}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            data-testid="dialog-submit-button"
            loading={
              isFetchingProjectData ||
              isUpdatingProjectData ||
              isFetchingProjectListData
            }
            onClick={() => {
              handleActivityStatusChange(newActivityStatus)
            }}
            startIcon={<Check />}
          >
            {t(
              `projectDetails.activityConfirmationDialog.${newActivityStatus}.confirmBtn`
            )}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectActivityConfirmationDialog
