import { Alert, Grid } from "@mui/material"
import { useAtom } from "jotai"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UploadedDocument } from "src/features/onboarding"
import { onboardingDocumentDataAtom } from "src/shared/stores"

const UploadedDocumentsList = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "onboardingPage.documentsFormData"
  })
  const { watch } = useFormContext()
  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const isExemptFromTaxFiling = watch("isExemptFromTaxFiling")

  return (
    <Grid item container xs={12} className="McpDocuments" spacing={2}>
      {uploadedOnboardingDocuments && uploadedOnboardingDocuments.length > 0 ? (
        uploadedOnboardingDocuments.map((document: OnboardingDocument) => (
          <Grid key={document.documentId} item xs={12} md={6}>
            <UploadedDocument
              key={document.documentId}
              document={document}
              isDeletable={true}
              isDownloadable={true}
            />
          </Grid>
        ))
      ) : (
        <Grid item md={12} lg={6}>
          <Alert
            severity="warning"
            data-testid="noUploadedOnboardingDocumentsAlert"
          >
            {t(
              !isExemptFromTaxFiling
                ? "minDocumentsAlert"
                : "noUploadedDocumentsAlert"
            )}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default UploadedDocumentsList
