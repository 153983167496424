import { Check, ChevronLeft, ChevronRight } from "@mui/icons-material"
import {
  Box,
  Button,
  Container,
  Step,
  StepButton,
  StepLabel,
  Stepper
} from "@mui/material"
import { useAtom, useAtomValue } from "jotai"
import { useSnackbar } from "notistack"
import { ReactNode, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ApiError } from "src/shared/client"
import { filterFormData } from "src/shared/functions"
import { routes } from "src/shared/routing"
import {
  deleteOnboardingDocumentDataAtom,
  onboardingCompanyLanguageAtom,
  onboardingDataAtom,
  onboardingDocumentDataAtom,
  postOnboardingStatusChangeAtom,
  updateOnboardingDataAtom,
  userAtom
} from "src/shared/stores"

const OnboardingStepper = ({ children }: { children: JSX.Element[] }) => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingPage" })

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { handleSubmit, watch } = useFormContext<OnboardingRecord>()
  const isExemptFromTaxFiling = watch("isExemptFromTaxFiling")
  const termsAndConditionsAccepted = watch("termsAndConditionsAccepted")

  const [{ data: user }] = useAtom(userAtom)
  const [{ refetch: refetchOnboardingData }] = useAtom(onboardingDataAtom)
  const [
    { mutateAsync: updateOnboardingData, isPending: isUpdatingOnboardingData }
  ] = useAtom(updateOnboardingDataAtom)
  const [{ mutateAsync: postNewStatusChange, isPending: isUpdatingStatus }] =
    useAtom(postOnboardingStatusChangeAtom)
  const [{ data: uploadedOnboardingDocuments }] = useAtom(
    onboardingDocumentDataAtom
  )
  const [{ isPending: isDeletingDocument }] = useAtom(
    deleteOnboardingDocumentDataAtom
  )
  const onboardingCompanyLanguage = useAtomValue(onboardingCompanyLanguageAtom)

  const [step, setStep] = useState(0)
  const [activeStepComponent, setActiveStepComponent] = useState(<></>)
  const [completed, setCompleted] = useState<{ [step: number]: boolean }>({})

  useEffect(() => {
    if (children.length > 0) {
      const activeChild = children.filter((_, index) => index == step)[0]
      setActiveStepComponent(activeChild)
    }
  }, [children, step])

  useEffect(() => {
    if (searchParams.get("step")) {
      const step = Number(searchParams.get("step"))
      setStep(step - 1)

      navigate(`/onboarding?${searchParams.toString()}`)
    } else if (!searchParams.get("step")) {
      setStep(0)
      searchParams.set("step", "1")
      navigate(`/onboarding?${searchParams.toString()}`)
    }
  }, [navigate, searchParams])

  const getDisplayName = (Component: ReactNode) => {
    switch (Component) {
      default:
        return t("merchantFormData.displayName")
      case children[1]:
        return t("contactFormData.displayName")
      case children[2]:
        return t("accountFormData.displayName")
      case children[3]:
        return t("documentsFormData.displayName")
      case children[4]:
        return t("summaryFormData.displayName")
    }
  }

  const handleUpdateOnboarding = (
    e: React.BaseSyntheticEvent,
    goToStep: number
  ) =>
    handleSubmit(
      async (formData) => {
        try {
          if (formData.status === "NOT_YET_STARTED") {
            await updateOnboardingData({
              updatedOnboardingData: filterFormData({
                ...formData,
                companyLanguage: onboardingCompanyLanguage,
                status: "IN_PROGRESS"
              }) as OnboardingRecord
            })
          } else {
            await updateOnboardingData({
              updatedOnboardingData: filterFormData({
                ...formData,
                companyLanguage: onboardingCompanyLanguage,
                bankAccount: { ...formData.bankAccount, accountHolder: null }
              }) as OnboardingRecord
            })
          }

          setCompleted({ ...completed, [step]: true })
          refetchOnboardingData()
          searchParams.set("step", String(goToStep + 1))
          navigate(`/onboarding?${searchParams.toString()}`)
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        console.error(errors)
      }
    )(e)

  const handleSubmitOnboarding = (e: React.BaseSyntheticEvent) =>
    handleSubmit(
      async (formData) => {
        try {
          await updateOnboardingData({
            updatedOnboardingData: filterFormData({
              ...formData,
              companyLanguage: onboardingCompanyLanguage,
              bankAccount: { ...formData.bankAccount, accountHolder: null }
            }) as OnboardingRecord
          })

          await postNewStatusChange({
            status: "READY_FOR_APPROVAL",
            author: `${user?.given_name} ${user?.family_name}`
          })

          setCompleted({ ...completed, [step]: true })
          await refetchOnboardingData()
          navigate(routes.dashboard)
        } catch (error) {
          if (error instanceof ApiError) {
            const response: ServiceError = await error.errorResponse.json()

            enqueueSnackbar({
              variant: "detailedSnackbar",
              message: t(response.code, { keyPrefix: "errorCodes" }),
              details: response.message,
              autoHideDuration: null
            })
          }
        }
      },
      (errors) => {
        console.error(errors)
      }
    )(e)

  const onJumpToStep = async (
    e: React.BaseSyntheticEvent,
    jumpToStep: number
  ) => {
    e.preventDefault()
    refetchOnboardingData()
    searchParams.set("step", String(jumpToStep + 1))
    navigate(`/onboarding?${searchParams.toString()}`)
  }

  const onPrevious = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    refetchOnboardingData()
    searchParams.set("step", String(step))
    navigate(`/onboarding?${searchParams.toString()}`)
  }

  return (
    <Container component="form" data-testid="onboarding-stepper" maxWidth="md">
      <Stepper nonLinear activeStep={step} alternativeLabel>
        {children.map((_, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton
              data-testid={`stepBtn${index}`}
              onClick={(e) => onJumpToStep(e, index)}
            >
              <StepLabel>{getDisplayName(children[index])}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {activeStepComponent}

      <Box className="McpButtons">
        {step !== 0 && (
          <Button
            color="primary"
            variant="outlined"
            disabled={
              isDeletingDocument || isUpdatingOnboardingData || isUpdatingStatus
            }
            disableRipple
            data-testid="stepperBackBtn"
            onClick={onPrevious}
            startIcon={<ChevronLeft />}
          >
            {t("onboardingStepper.backBtn")}
          </Button>
        )}
        <Button
          disabled={
            (step === 4 &&
              Object.keys(completed).length <= 4 &&
              !termsAndConditionsAccepted) ||
            (step === 3 &&
              !isExemptFromTaxFiling &&
              uploadedOnboardingDocuments?.length === 0) ||
            isDeletingDocument ||
            isUpdatingOnboardingData ||
            isUpdatingStatus
          }
          id="nextButton"
          disableRipple
          data-testid={
            step === children.length - 1 ? "stepperSubmitBtn" : "stepperNextBtn"
          }
          variant="contained"
          type="submit"
          endIcon={step === children.length - 1 ? <Check /> : <ChevronRight />}
          color={step === children.length - 1 ? "primary" : "primary"}
          onClick={
            step === children.length - 1
              ? (e) => handleSubmitOnboarding(e)
              : (e) => handleUpdateOnboarding(e, step + 1)
          }
        >
          {step === children.length - 1
            ? t("onboardingStepper.submitBtn")
            : t("onboardingStepper.nextBtn")}
        </Button>
      </Box>
    </Container>
  )
}
export default OnboardingStepper
