const saveFile = (file: Blob, filename: string) => {
  const element = document.createElement("a")
  const url = URL.createObjectURL(file)
  element.href = URL.createObjectURL(file)
  element.download = filename
  // Required for this to work in FireFox
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
  window.URL.revokeObjectURL(url)
}

export default saveFile
