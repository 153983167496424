import { ChipOwnProps } from "@mui/material"
import { useTranslation } from "react-i18next"

type LabelProps = {
  onboardings?: string
  merchants?: string
  projects?: string
}

const useChipConfig = () => {
  const { t } = useTranslation("translation")
  const chipLabel: Record<UnionStatus, LabelProps> = {
    ALL: {
      onboardings: t("chip.all"),
      projects: t("chip.all"),
      merchants: t("chip.all")
    },
    READY_FOR_APPROVAL: { projects: t("chip.reviewed") },
    WAITING_FOR_MERCHANT: { projects: t("chip.resubmitted") },
    APPROVED: {
      projects: t("chip.approved")
    },
    DECLINED: {
      projects: t("chip.declined")
    },
    ACTIVE: {
      projects: t("chip.active")
    },
    INACTIVE: {
      projects: t("chip.inactive")
    }
  }

  const CHIP_DEFAULT_TEXT = t("chip.defaultText")

  const chipColor: Record<UnionStatus, ChipOwnProps["color"]> = {
    ALL: "primary",
    READY_FOR_APPROVAL: "info",
    WAITING_FOR_MERCHANT: "warning",
    APPROVED: "success",
    DECLINED: "error",
    ACTIVE: "success",
    INACTIVE: "default"
  }
  return { chipLabel, chipColor, CHIP_DEFAULT_TEXT }
}

export default useChipConfig
