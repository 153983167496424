import { KeyboardArrowRight } from "@mui/icons-material"
import { Box, Button, Grid, Typography } from "@mui/material"
import { useAtom, useSetAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { CreateProjectDialog } from "src/features/project"
import { merchantDataAtom, projectIdAtom } from "src/shared/stores"
import ProjectDetails from "src/widgets/projectDetails"
import ProjectList from "src/widgets/projectList"

const ProjectsPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "projectPage"
  })

  const { projectId } = useParams()

  const setProjectId = useSetAtom(projectIdAtom)
  const [{ data: merchantData }] = useAtom(merchantDataAtom)

  const [open, setOpen] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDrawerOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDialogOpen = useCallback(() => {
    setIsDialogOpen(true)
  }, [])

  const toggleIsDialogOpen = useCallback(() => {
    setIsDialogOpen(!isDialogOpen)
  }, [isDialogOpen])

  useEffect(() => {
    if (projectId) {
      setProjectId(projectId)
      setOpen(true)
    } else {
      setOpen(false)
      setProjectId(undefined)
    }
  }, [projectId, setProjectId])

  return (
    <Grid
      container
      item
      className={`${open ? "McpDrawerOpen" : ""} McpContentGrid`}
    >
      <Grid
        container
        item
        className="McpContentView"
        data-testid="projectsPage"
      >
        {/* HEADLINE */}
        <Box className={"McpContentViewSticky"}>
          <Grid container item alignItems={"center"}>
            <Grid item xs="auto">
              <Typography variant="h2">{t("pageHeadline")}</Typography>
              <Typography variant="body2">{t("pageSubheadline")}</Typography>
            </Grid>
            {merchantData?.status === "ACTIVE" && (
              <Grid item xs="auto" ml="auto">
                <Button
                  data-testid="newProjectButton"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleDialogOpen}
                  endIcon={<KeyboardArrowRight />}
                >
                  {t("newProjectButton")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box className="McpContentViewScroll" data-testid="projectTable">
          <ProjectList
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        </Box>
      </Grid>

      {open && (
        <Grid className="McpDrawer">
          <ProjectDetails open={open} handleDrawerClose={handleDrawerClose} />
        </Grid>
      )}

      <CreateProjectDialog
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={toggleIsDialogOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
    </Grid>
  )
}

export default ProjectsPage
