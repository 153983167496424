const handleDate = (date?: string) =>
  !date
    ? "--"
    : `${new Date(date).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })}`

export default handleDate
