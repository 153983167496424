import { Box, CircularProgress, Typography } from "@mui/material"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"

const Dropzone = ({
  handleFileUpload,
  isUploading
}: {
  handleFileUpload: (acceptedFiles: File[]) => void
  isUploading: boolean
}) => {
  const { t } = useTranslation("translation")

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFileUpload(acceptedFiles)
    },
    [handleFileUpload]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    useFsAccessApi: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/bmp": [],
      "application/pdf": []
    }
  })

  return (
    <Box style={{ position: "relative" }}>
      <Box
        className={`dropzone ${isDragActive && "dropzone-drag"}`}
        id="onboardingDropzone"
        {...getRootProps()}
      >
        <Box
          disabled={isUploading}
          component="input"
          type="file"
          {...getInputProps()}
        />
        <Typography>
          {t(
            isUploading
              ? "onboardingPage.documentsFormData.innerDropzoneTextUpload"
              : "onboardingPage.documentsFormData.innerDropzoneText"
          )}
        </Typography>
      </Box>

      {isUploading && (
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            backgroundColor: "rgba(255, 255, 255, 0.8)"
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default Dropzone
