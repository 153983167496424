import { atom } from "jotai"
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query"
import { ApiError, get as getClient, put as putClient } from "src/shared/client"
import { userAtom } from "src/shared/stores"

type UpdateOnboardingDataInput = {
  updatedOnboardingData: OnboardingRecord
  signal?: AbortSignal
}

export const onboardingCompanyLanguageAtom = atom((get) => {
  const { data: user } = get(userAtom)

  return user?.locale?.substring(0, 2) ?? "de"
})

const defaultOnboardingDataAtom = atom<OnboardingRecord>((get) => {
  const { data: user } = get(userAtom)

  const defaultOnboardingData: OnboardingRecord = {
    onboardingId: user?.sub ?? "",
    userId: user?.sub ?? "",
    companyLanguage: user?.locale?.substring(0, 2) ?? "de",
    bankAccount: undefined,
    address: {
      city: "",
      country: "DE",
      houseNumber: "",
      addressAddition: "",
      state: "",
      street: "",
      zip: ""
    },
    ceo: {
      personId: undefined,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    companyName: "",
    companyNameAddition: "",
    companyRevenue: "",
    companySize: "",
    dataProtectionOfficer: {
      personId: undefined,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    isDataProtectionOfficerExternal: false,
    isSoleProprietorship: false,
    isExemptFromTaxFiling: false,
    ceoDeputies: [],
    development: undefined,
    finance: undefined,
    homePage: "",
    legalForm: "",
    phone: "",
    status: "NOT_YET_STARTED",
    support: undefined,
    techSupport: undefined,
    termsAndConditionsAccepted: false
  }

  return defaultOnboardingData
})

export const onboardingDataAtom = atomWithQuery<OnboardingRecord, ApiError>(
  (get) => {
    const { data: user } = get(userAtom)
    const defaultOnboardingData = get(defaultOnboardingDataAtom)

    return {
      queryKey: ["onboardingData"],
      queryFn: async () => {
        try {
          const response = await (
            await getClient({
              path: `/merchant-service/onboardings/${user?.sub}`
            })
          ).json()

          return response
        } catch (error) {
          return defaultOnboardingData
        }
      },
      enabled: !!user?.sub
    }
  }
)

export const updateOnboardingDataAtom = atomWithMutation<
  OnboardingRecord,
  UpdateOnboardingDataInput,
  ApiError
>((get) => {
  const { data: user } = get(userAtom)

  return {
    mutationFn: async ({
      updatedOnboardingData,
      signal
    }): Promise<OnboardingRecord> => {
      const response = await (
        await putClient({
          path: `/merchant-service/onboardings/${user?.sub}`,
          body: updatedOnboardingData,
          signal
        })
      ).json()

      return response
    }
  }
})
